import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import ErrorPage from "./ErrorPage";
import Leistung from "./pages/Leistung";
import Page from "./pages/Page";
import App from "./App";
import News from "./pages/News";

const root = ReactDOM.createRoot(document.getElementById('root'));

const api_root_url = process.env.REACT_APP_API_ROOT_URL


const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "",
                element: <Page />,
                loader: async ({ request, params }) => {
                    return fetch(
                        `${api_root_url}/api/pages?filters[slug][$eq]=willkommen&populate[header][populate]=*&populate[seo][populate]=*`,
                        { signal: request.signal }
                    );
                },
            },
            {
                path: "/aktuelles",
                element: <Page />,
                loader: async ({ request, params }) => {
                    return fetch(
                        `${api_root_url}/api/pages?filters[slug][$eq]=willkommen&populate[header][populate]=*&populate[seo][populate]=*`,
                        { signal: request.signal }
                    );
                },
            },
            {
                path: ":pageId",
                element: <Page />,
                // with this data loaded before rendering
                loader: async ({ request, params }) => {
                    return fetch(
                        `${api_root_url}/api/pages?filters[slug][$eq]=${params.pageId}&populate[header][populate]=*&populate[seo][populate]=*`,
                        { signal: request.signal }
                    );
                },
            },
            {
                path: "leistungen/:leistungId",
                element: <Leistung />,
                loader: async ({ request, params }) => {
                    return fetch(
                        `${api_root_url}/api/leistungen?filters[slug][$eq]=${params.leistungId}&populate[header][populate]=*&populate[gallery][populate]=*&populate[seo][populate]=*`,
                        { signal: request.signal }
                    );
                },
            },
            {
                path: "aktuelles/:newsId",
                element: <News />,
                loader: async ({ request, params }) => {
                    return fetch(
                        `${api_root_url}/api/neuigkeiten?filters[slug][$eq]=${params.newsId}&populate[header][populate]=*&populate[seo][populate]=*`,
                        { signal: request.signal }
                    );
                },
            },
        ],
    },
]);

root.render(
  <React.StrictMode>
          <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
