import React, {useEffect, useState} from "react";
import {Link, useLoaderData} from "react-router-dom";
import {BlocksRenderer} from '@strapi/blocks-react-renderer';
import OfferOverview from "../components/OfferOverview";
import NewsOverview from "../components/NewsOverview";
import Banner from "../components/Banner";
import {ReactComponent as Rings} from "../rings.svg"
import {ReactComponent as SmallRings} from "../rings_subset.svg";
import classNames from "classnames";
import {useScrollPosition} from '@n8tb1t/use-scroll-position'
import axios from "axios";
import MainContent from "../components/MainContent";
import Seo from "../components/Seo";


export default function Page(props) {
    const [maxRingHeight, setMaxRingHeight] = useState("120vh")
    const [siteInfo, setSiteInfo] = useState({})

    const api_root_url = process.env.REACT_APP_API_ROOT_URL


    // Element scroll position
    useScrollPosition(
        ({currPos}) => {
            const inlineStyle = `calc(120vh - ${currPos.y * 0.33 * -1}px)`
            setMaxRingHeight(String(inlineStyle))
        }, [],
    )

    let data = useLoaderData()
    let attr = undefined
    let title, slug, published = undefined
    let content = []

    useEffect(() => {
        axios.get(`${api_root_url}/api/info`).then((response) => {
            const attributes = (response.data.data.attributes)
            setSiteInfo(attributes)
        }).catch((error) => {
            console.error(error)
        })
    }, [api_root_url]);


    if (data === undefined || data === null) {
        return <div>No data</div>;
    } else {
        data = data.data[0]
        attr = data.attributes
        slug = attr["slug"]
        title = attr["title"]
        content = attr["content"] ? attr["content"] : []
        published = attr["publishedAt"]

        const front = slug === "" || slug === "willkommen"

        const site_title = siteInfo.title ? siteInfo.title : undefined
        const site_teaser = siteInfo.teaser ? siteInfo.teaser : undefined
        const telephone = siteInfo["telephone"] ? siteInfo["telephone"] : "-"
        const fax = siteInfo["fax"] ? siteInfo["fax"] : "-"
        const email = siteInfo["email"] ? siteInfo["email"] : "-"
        const address = siteInfo["address"] ? siteInfo["address"] : []


        return (
            <div className={"main flex flex-row flex-wrap justify-center"}>
                <Seo data={attr["seo"]} published={published} full_slug={`${slug}`} content_type={"website"}
                     site_title={title}/>
                {front &&
                    <div
                        className={"welcome flex flex-row flex-wrap bg-accent pt-8 pb-24 md:pb-24 md:pt-24 md:py-0 md:min-h-screen w-full overflow-hidden"}>
                        <div
                            className={"w-full md:w-[25%] xl:w-[40%] h-fit md:h-full flex justify-center md:justify-end overflow-visible"}>
                            <Rings className={classNames("rotating-rings w-full h-auto px-12 md:px-4",
                                "md:-top-1/3 md:-left-3/4 xl:-left-1/2 md:absolute", "md:motion-safe:animate-[spin_90s_linear_infinite]")}
                                   style={{maxHeight: maxRingHeight}}/>
                        </div>
                        <div
                            className={"w-full md:w-[75%] xl:w-[60%] xl:max-w-[1300px] pt-12 xl:pt-24 min-[1920px]:py-52 text-white flex flex-row flex-wrap justify-start align-top px-4 md:px-0 2xl:pl-24"}>

                            {site_title &&
                                <h1 className={"w-full text-5xl md:text-6xl xl:text-8xl text-white min-[1920px]:text-[12em] leading-[0.8em] tracking-tight uppercase font-black md:mt-[20vh]"}>{site_title}</h1>
                            }

                            {site_teaser &&
                                <div className={"lead text-2xl w-full min-[1920px]:text-3xl md:w-3/4 leading-9"}>
                                    <BlocksRenderer content={site_teaser}/>
                                </div>
                            }

                        </div>
                    </div>
                }

                <main>


                    {attr && attr.header !== undefined &&
                        <Banner data={attr.header} isFront={front}/>
                    }

                    <div className={"flex flex-col items-center w-full overflow-hidden"}>
                        {front &&
                            <div className={"w-full max-w-screen-2xl px-4  flex flex-row flex-wrap justify-center"}>
                                <h1 published={published} data-text={`${title}`}
                                    className={"heading-effect-a w-full max-w-4xl"}>{title}</h1>

                                <div
                                    className={"w-full lg:w-3/5 flex flex-row flex-wrap justify-start lg:pr-32 my-6 lg:mt-12 text-xl lg:text-2xl"}>
                                    <div className={"main-content w-full"}><BlocksRenderer content={content}/></div>
                                    <Link to={"/ueber-uns"}
                                          className={classNames("self-start border lg:border-2 border-accent rounded-md",
                                              "transition-all duration-300 text-base",
                                              "mt-6 py-2.5 px-6 uppercase text-accent text-center w-full lg:w-auto",
                                              "hover:text-white hover:bg-accent")}>
                                        Mehr über uns erfahren
                                    </Link>
                                </div>

                                <div
                                    className={"contact-teaser bg-accent text-white rounded-lg lg:rounded-3xl mt-16 lg:mt-64 w-full lg:w-2/5 flex flex-row flex-wrap self-center overflow-hidden"}>
                                    <div className={"w-full lg:w-2/3 p-4 lg:py-12 lg:pl-12 flex flex-col"}>
                                        <span className={"font-bold mb-1.5"}>So erreichen Sie uns:</span>

                                        <div className={"mail-and-phone"}>
                                            {telephone !== "-" &&
                                                <span>Telefon: <a href={`tel:${telephone}`}>{telephone}</a><br/></span>}
                                            {fax !== "-" && <span>Fax: <a href={`tel:${fax}`}>{fax}</a><br/></span>}
                                            {email !== "-" &&
                                                <span>E-Mail: <a href={`mailto:${email}`}>{email}</a><br/></span>}
                                        </div>

                                        <address className={"my-5"}>
                                            <BlocksRenderer content={address}/>
                                        </address>

                                        <Link to={"/kontakt"} className={classNames(
                                            "flex-initial border border-white rounded-md text-base",
                                            "py-2.5 px-6 uppercase text-center hover:text-accent hover:bg-white",
                                            "transition-all duration-300")}>
                                            Termin vereinbaren
                                        </Link>
                                    </div>
                                    <div className={"hidden lg:w-1/3 lg:flex"}>
                                        <div className={"w-full h-full flex justify-end overflow-visible"}>
                                            <SmallRings
                                                className={classNames("w-auto -mt-[150px] -mr-[100px] motion-safe:animate-[spin_90s_linear_infinite]")}
                                                style={{height: "400px",}}/>
                                        </div>
                                    </div>
                                </div>

                                <div className={"w-full max-w-4xl my-24 lg:my-48"}>
                                    <NewsOverview/>
                                </div>
                            </div>
                        }


                        {!front &&
                            <MainContent published={published} title={title} content={content}/>
                        }


                        {slug === "kontakt" &&
                            <main className={"contact-info text-xl leading-relaxed w-full max-w-7xl mb-24 px-4"}>
                                <h3 className={"font-bold"}>So erreichen Sie uns</h3>

                                <div className={"mail-and-phone"}>
                                    {telephone !== "-" &&
                                        <span>Telefon: <a href={`tel:${telephone}`}>{telephone}</a><br/></span>}
                                    {fax !== "-" && <span>Fax: <a href={`tel:${fax}`}>{fax}</a><br/></span>}
                                    {email !== "-" && <span>E-Mail: <a href={`mailto:${email}`}>{email}</a><br/></span>}
                                </div>

                                <address className={"my-5"}>
                                    <BlocksRenderer content={address}/>
                                </address>
                            </main>

                        }


                        {(front || slug === "leistungen") &&
                            <div>
                                {front && <h2 className="heading-effect-b" data-text={title}><Link
                                    to={"/leistungen"}>Leistungen</Link></h2>}
                                <OfferOverview full_content={true}/>
                            </div>
                        }

                        {slug === "ueber-uns" &&
                            <Link to={"/kontakt"}
                                  className={"uppercase text-accent border border-accent rounded-md py-2.5 mb-24 px-8 hover:text-white hover:bg-accent transition-all duration-300"}>Termin
                                vereinbaren</Link>
                        }

                    </div>

                </main>


            </div>
        )
    }
}