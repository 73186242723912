import axios from "axios";
import React, {useEffect, useState} from "react";
import {Outlet, ScrollRestoration, useLocation} from "react-router-dom";

import {Helmet} from "react-helmet";
import ContentHeader from "./components/ContentHeader"

import {ReactComponent as LogoWhite}  from "./rings_white_1200.svg";
import './App.css'
import favicon from "./favicon.ico"
import {BlocksRenderer} from "@strapi/blocks-react-renderer";
import OfferOverview from "./components/OfferOverview";
import FooterNavigation from "./components/nav/FooterNavigation";


const App = () => {
    const location = useLocation()
    const current_path = location.pathname

    const api_root_url = process.env.REACT_APP_API_ROOT_URL

    const [siteInfo, setSiteInfo] = useState({})
    const [seoTitle, setSeoTitle] = useState("Tischlerei Kroll")
    const [seoDescription, setSeoDescription] = useState("Ihre leistungsfähige, kompetente und serviceorientierte Tischlerei für den Raum Essen und Hattingen.")
    const [seoKeywords, setSeoKeywords] = useState("Tischlerei, Essen, Hattingen, Möbelbau, Fenster, Türen, Restaurierung, Sicherheit, Insektenschutz")


    useEffect(() => {

        axios.get(`${api_root_url}/api/info?populate=*`).then((response) =>{
            const attributes =(response.data.data.attributes)
            const seo = attributes.seo ?? undefined

            if(seo.constructor === Object) {
                setSeoDescription(seo["metaDescription"] !== null ? seo["metaDescription"] : seoDescription)
                setSeoTitle(seo["metaTitle"] !== null ? seo["metaTitle"] : seoTitle)
                setSeoKeywords(seo["keywords"] !== null ? seo["keywords"] : seoKeywords)
            }

            setSiteInfo(attributes)


            }).catch((error) => {
            console.error(error)
        })
    }, [seoTitle,seoDescription,seoKeywords,api_root_url]);


    const telephone = siteInfo["telephone"] ? siteInfo["telephone"] : "-"
    const fax = siteInfo["fax"] ? siteInfo["fax"] : "-"
    const email = siteInfo["email"] ? siteInfo["email"] : "-"
    const address = siteInfo["address"] ? siteInfo["address"] : []
    const title = siteInfo["Title"] ? siteInfo["title"] : "Tischlerei Kroll"
    const info = siteInfo["info"] ? siteInfo["info"] : []


    return (
        <div className="main dark:bg-slate-800 text-slate-900 dark:text-white text-base font-medium tracking-tight">
            <Helmet>
                <title>{seoTitle} – Ihr Partner für den Raum Essen und Hattingen</title>
                <meta name="description" content={seoDescription}/>
                <meta name="keywords" content={seoKeywords}/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta property={"og:locale"} content={"de_DE"}/>
                <meta property={"og:type"} content={"website"}/>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16"/>
            </Helmet>
            <ContentHeader active_trail={current_path} />
            <Outlet/>
            <ScrollRestoration />
            <footer>
                <div className={"w-full p-5 mt-12 py-16 bg-accent text-white flex flex-row flex-wrap justify-center"}>
                    <div className={"w-full max-w-screen-2xl flex flex-row flex-wrap justify-start"}>
                        <div className={"w-full lg:w-4/5 flex flex-row flex-wrap"}>
                            <div className={"w-full lg:w-2/6 flex flex-col justify-start"}>
                                <LogoWhite className={"w-full h-auto self-start px-12"}/>
                            </div>
                            <div className={"w-full lg:w-4/6 max-w-7xl lg:w-6/8"}>
                                <div className={"w-full lg:w-1/2"}>
                                    <h2>{title}</h2>
                                    <div className={"short-info"}>
                                        <BlocksRenderer content={info}/>
                                    </div>


                                    <div className={"mail-and-phone"}>
                                        {email !== "-" && <span><a href={`mailto:${email}`}>{email}</a><br/></span>}
                                        {telephone !== "-" &&
                                            <span><a href={`tel:${telephone}`}>{telephone}</a><br/></span>}
                                        {fax !== "-" && <span>Fax: <a href={`fax:${fax}`}>{fax}</a></span>}
                                    </div>

                                    <address className={"my-5"}>
                                        <BlocksRenderer content={address}/>
                                    </address>


                                    <div className={"leistungen w-full"}>
                                        <p className={"mb-0 mt-12"}>Ihr Experte für</p>
                                        <OfferOverview full_content={false}/>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div className={"w-full lg:w-1/5 lg:w-2/8 lg:mt-0"}>
                                <FooterNavigation active_trail={current_path}/>
                        </div>


                    </div>

                </div>
            </footer>


        </div>
    );
};

export default App;