import {useEffect, useState} from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import {BlocksRenderer} from "@strapi/blocks-react-renderer";
import classNames from "classnames";

function getBanner(data) {
    if(data !== undefined) {
        const attr = data.data.attributes
        return [attr.formats.sm.url, attr.alternativeText]
    } else {
        return undefined
    }
}
const api_root_url = process.env.REACT_APP_API_ROOT_URL


export default function OfferOverview(props) {
    const [error, setError] = useState(null);
    const [leistungen, setLeistungen] = useState([]);
    const full_content = props.full_content
    useEffect(() => {
        axios
            .get(`${api_root_url}/api/leistungen/?populate[header][populate]=*`)
            .then(({data}) => {
                setLeistungen(data.data)
            })
            .catch((error) => setError(error));
    }, []);

    if (error) {
        // Print errors if any
        return <div>An error occurred: {error.message}</div>;
    }


    leistungen.sort((l1, l2) => (l1.attributes.weight < l2.attributes.weight) ? 1 : (l1.attributes.weight > l2.attributes.weight) ? -1 : 0)

    const offer_list = Object.entries(leistungen).map(([key, value]) => {
        const attr = value.attributes
        const teaser = attr.teaser ? attr.teaser : []
        const thumbnail = getBanner(attr.header.portrait)
        if(full_content) {
            return <li key={`leistung-${attr.title}`} className={"w-full sm:w-1/2 lg:w-1/3 p-5"}>
                <Link to={`/leistungen/${attr.slug}`} className={"flex transition-all duration-300 w-full relative group overflow-hidden"}>
                    <div className={"image w-full"}>
                        {thumbnail !== undefined &&
                        <img width={attr.header.portrait.data.attributes.formats.sm.width} height={attr.header.portrait.data.attributes.formats.sm.height} loading="lazy" src={`${api_root_url}${thumbnail[0]}`} alt={thumbnail[1] !== undefined ? "" : thumbnail[1]}/>
                        }
                    </div>
                    <div className={classNames(
                        "absolute top-0 right-0 h-full text-white",
                        "transition-all duration-300 group-hover:cursor-pointer")}>
                        <div className={classNames("w-full h-full p-5 flex flex-col justify-start items-end",
                            "transition-all duration-500", "-my-36 group-hover:my-0",
                            "translate-y-full group-hover:translate-y-0",
                            "bg-accent bg-opacity-0 group-hover:bg-opacity-80 group-hover:backdrop-blur-lg")}>
                            <h3 className={"w-full !h-24 mb-10 flex flex-col items-start justify-end !text-xl lg:!text-2xl xl:!text-3xl  2xl:!text-4xl uppercase font-black"}>{attr.title}</h3>
                            <div className={"w-full flex flex-row flex-wrap items-start justify-center font-bold transition-all duration-700 opacity-0 group-hover:opacity-100"}>
                                <div className={"w-full"}>
                                    <BlocksRenderer content={teaser}/>
                                </div>
                                <button className={"w-10/12 p-3.5 mt-10 uppercase transition-all duration-300 border border-white rounded-lg hover:bg-white hover:text-accent"}>
                                    Mehr erfahren
                                </button>
                            </div>
                        </div>
                    </div>
                </Link>
            </li>
        } else {
            return <li key={`links-leistung-${attr.title}`}>
                &rsaquo;&nbsp;<Link className="border-b border-transparent hover:border-white transition-all duration-150" to={`/leistungen/${attr.slug}`}>{attr.title}</Link>
            </li>
        }

    })

    if(full_content) {
        return (
            <div className={"teaser-offer-list my-12 lg:my-24"}>
                <ul className={"w-full max-w-7xl flex flex-row flex-wrap"}>
                    {offer_list}
                </ul>
            </div>
        )
    } else {

    }

    return (
        <div className={"link-offer-list"}>
            <ul className={"w-full flex flex-col flex-wrap"}>
                {offer_list}
            </ul>
        </div>
    )

}