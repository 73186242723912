import {BlocksRenderer} from "@strapi/blocks-react-renderer";

export default function MainContent(props) {
    return (
        <div
            className={"inner w-full lg:max-w-7xl px-4 flex flex-row flex-wrap justify-start"}>
            <h1 published={props.published} data-text={props.title} className={"heading-effect-a"}>
                {props.title}
            </h1>
            <div className={"text-lg lg:text-xl leading-relaxed mb-12 main-content"}>
                <BlocksRenderer content={props.content}/>
            </div>
        </div>
    )
}
