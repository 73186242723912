import {useEffect, useState} from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import classNames from "classnames";

export default function MainNavigation(props) {
    const [error, setError] = useState(null);
    const [navigation, setNavigation] = useState([]);
    const [toggleMenu, setToggleMenu] = useState(false)

    const api_root_url = process.env.REACT_APP_API_ROOT_URL

    useEffect(() => {
        axios.get(`${api_root_url}/api/navigation/render/main-navigation?type=TREE`,
                // query URL without using browser cache
            )
            .then(({data}) => {
                setNavigation(data)
            })
            .catch((error) => setError(error));
    }, [api_root_url]);

    if (error) {
        // Print errors if any
        return <div>An error occurred: {error.message}</div>;
    }

    function isActive(nav_item) {
        return props.active_trail === nav_item.path
    }

    const isFront = props.active_trail === "/" || props.active_trail === "/willkommen"


    return (
        <div className={classNames(isFront ? "front" : "", props.isScrolled ? "scrolled" : "", "main-nav w-full lg:w-auto")}>
            <input onChange={() => {setToggleMenu(!toggleMenu)}} checked={toggleMenu} className="toggle-menu" type="checkbox" id="main-menu"/>
            <label className={classNames(isFront && !props.isScrolled ? "bg-transparent" : "bg-white/40", "menu-icon rounded-lg z-50")} htmlFor="main-menu"><span className="menu-line"></span></label>
            <nav className={classNames(props.isScrolled ? "scrolled" : "",
                "main-nav fixed antialiased transition-all duration-300",
                "w-full h-0 overflow-hidden top-0 left-0 right-0 z-20",
                "backdrop-blur-lg bg-accent/80",
                "lg:w-fit lg:h-fit lg:left-auto lg:top-12 lg:right-5 xl:right-20 lg:mr-2.5 lg:p-5 lg:rounded-lg")}>
                <ul className={"flex flex-col lg:flex-row mt-24 lg:mt-auto"}>
                    {navigation.map((nav_item, index) => (
                        <li key={nav_item.slug}
                            className={classNames(
                                 "uppercase font-bold border-b hover:border-white transition-all duration-150 text-white",
                                "w-full text-center",
                                "lg:w-fit lg:px-0 lg:py-0 lg:text-left lg:mx-2.5 lg:pb-2.5 lg:-mb-2.5",
                                isActive(nav_item) ? "bg-white !text-accent lg:bg-transparent lg:!text-white lg:border-white" : "bg-transparent text-white lg:border-transparent")}>
                            <Link onClick={() => setToggleMenu(false)} to={nav_item.path} target={nav_item.external ? "_blank" : "_self"}
                                  rel="noopener noreferrer" className={"inline-block w-full px-4 py-6  lg:px-1.5 lg:py-0.5"}>{nav_item.title}</Link>
                        </li>
                    ))}
                </ul>
            </nav>

        </div>


    )

}