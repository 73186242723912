import {Link, useLoaderData} from "react-router-dom";
import Banner from "../components/Banner";
import Gallery from "../components/Gallery";
import MainContent from "../components/MainContent";
import React from "react";
import Seo from "../components/Seo";


export default function Leistung(props) {
    let data = useLoaderData()

    let title, slug, published, attr = undefined
    let content, gallery = []


    if (data === undefined || data === null) {
        return <div>No data</div>;
    } else {
        data = data.data[0]
        attr = data.attributes
        slug = attr["slug"]
        title = attr["title"]
        content = attr["content"] ? attr["content"] : []

        gallery = Object.keys(attr["gallery"]).length > 0 ? attr["gallery"]["data"] : []
        published = attr["publishedAt"]
        content = data.attributes.content ? data.attributes.content : []


        return (
            <main className={"main"}>
                <Seo data={attr["seo"]} published={published} full_slug={`leistungen/${slug}`} content_type={"website"}
                     site_title={title}/>
                <Banner data={attr.header} isFront={false}/>

                <div className={"flex flex-col items-center w-full"}>
                    <div className={"inner w-full max-w-7xl flex flex-row flex-wrap justify-center"}>
                        <div className={"breadcrumbs text-lg lg:text-xl mb-5 flex flex-row justify-start w-full px-4"}>
                            <Link to={"/leistungen"}
                                  className={"border-b border-transparent transition-all duration-300 hover:text-accent hover:border-accent"}>Leistungen</Link>
                            <span className={"mx-1.5"}>&nbsp;&rsaquo;&nbsp;</span>
                            <Link to={`/leistungen/${slug}`}
                                  className={"border-b border-transparent transition-all duration-300 hover:text-accent hover:border-accent"}>{title}</Link>
                        </div>
                        <MainContent published={published} title={title} content={content}/>
                        <div className={"gallery"}>
                            <Gallery gallery={gallery}/>
                        </div>
                        <Link to={"/kontakt"}
                              className={"uppercase border border-accent text-accent rounded-md py-2.5 mb-24 px-8 hover:text-white hover:bg-accent transition-all duration-300"}>Termin
                            vereinbaren</Link>
                    </div>
                </div>
            </main>
        )
    }
}