import {Helmet} from "react-helmet";
import React from "react";

export default function Seo(props) {
    const data = props.data
    const site_title = props.site_title
    const content_type = props.content_type
    const published = props.published
    const full_slug = props.full_slug
    const api_root_url = process.env.REACT_APP_API_ROOT_URL

    let seo = undefined
    if(data !== null && Object.keys(data).length >= 1) {
        seo = {}
        const seo_data = data

        seo.title = seo_data.metaTitle ? seo_data.metaTitle : ""
        seo.description = seo_data.description ? seo_data.description : ""
        seo.metaDescription = seo_data.metaDescription ? seo_data.metaDescription : ""
        seo.keywords = seo_data.keywords ?seo_data.keywords : ""

        const image_data = seo_data.metaImage !== null ? seo_data.metaImage["data"]["attributes"] : undefined
        if(image_data ) {
            seo.image_url = `${api_root_url}${image_data.url}`
            seo.image_type = image_data.mime
            seo.image_width = image_data.width
            seo.image_height = image_data.height
            seo.image_alt = image_data.alternativeText
        }
    }
    return (
        <Helmet>
            <title>Tischlerei Kroll – {seo && seo.title !== "" ? seo.title : site_title}</title>
            <meta property={"og:url"} content={`https://www.tischlerei-kroll.ruhr/${full_slug}`}/>
            {(full_slug === "willkommen" || "aktuelles") && <link rel="canonical" href="https://www.tischlerei-kroll.ruhr/" />}
            <meta property={"og:type"} content={content_type}/>
            {published && <meta property={`og:${content_type}:published_time`} content={published}/>}

            {seo && seo.metaDescription !== "" && <meta name="description" content={seo.metaDescription}/>}
            {seo && seo.keywords !== "" && <meta name="keywords" content={seo.keywords}/>}
            {seo && seo.title !== "" && <meta property="og:title" content={seo.title}/>}
            {seo && seo.metaDescription !== "" && <meta property="og:description" content={seo.metaDescription} key={"og:key"}/>}

            {seo && seo.image_url && <meta property="og:image" content={seo.image_url} key={"og:image"}/>}
            {seo && seo.image_url && <meta property="og:image:secure_url" content={seo.image_url}/>}
            {seo && seo.image_type && <meta property="og:image:type" content={seo.image_type}/>}
            {seo && seo.image_width && <meta property="og:image:width" content={seo.image_width}/>}
            {seo && seo.image_height && <meta property="og:image:height" content={seo.image_height}/>}
            {seo && seo.image_alt && <meta property="og:image:alt" content={seo.image_alt}/>}
        </Helmet>
    )
}