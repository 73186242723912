import {useEffect, useState} from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import {BlocksRenderer} from "@strapi/blocks-react-renderer";
import {cloneDeep} from "lodash/lang";
import classNames from "classnames";

const api_root_url = process.env.REACT_APP_API_ROOT_URL


export default function NewsOverview() {
    const [error, setError] = useState(null);
    const [news, setNews] = useState([]);
    const [numNews, setNumNews] = useState(3);
    const [copiedLink, setCopiedLink] = useState("")
    const [focusNewsId, setFocusNewsId] = useState(undefined)
    useEffect(() => {
        axios
            .get(`${api_root_url}/api/neuigkeiten/?populate=*`)
            .then(({data}) => {
                setNews(data.data)
            })
            .catch((error) => setError(error));
    }, []);

    if (error) {
        // Print errors if any
        return <div>An error occurred: {error.message}</div>;
    }

    function checkDateDifference(published_timestamp, max_age_in_weeks) {
        const published = new Date(published_timestamp);
        const now = new Date();

        // Calculate the difference in milliseconds
        const difference = Math.abs(now - published);

        // Convert 2 weeks to milliseconds
        const x_weeks_in_ms = max_age_in_weeks * 7 * 24 * 60 * 60 * 1000;
        return difference < x_weeks_in_ms;
    }


    news.sort((n1, n2) => (n1.attributes.publishedAt < n2.attributes.publishedAt) ? 1 : (n1.attributes.publishedAt > n2.attributes.publishedAt) ? -1 : 0)

    const news_list = Object.entries(news).map(([key, value]) => {
        const attr = value.attributes
        const content = attr.content ? attr.content : 5
        let publishedAt = new Date(attr.publishedAt)
        let show_full_content = attr.show_full_content
        let content_teaser = cloneDeep(content)
        let show_more = false

        if (!show_full_content && content.length > 6) {
            content_teaser.splice(6, content_teaser.length)
            show_more = true
        }

        const news_link = `https://tischlerei-kroll.ruhr/aktuelles/${attr.slug}/`

        if (checkDateDifference(publishedAt, 24) && key < numNews) {
            return <li key={`neuigkeit-${attr.title}`} className={"mb-12 lg:mb-24"} onMouseEnter={() => {
                setFocusNewsId(key)
            }} onMouseLeave={() => {
                setCopiedLink("");
                setFocusNewsId(undefined)
            }}>
                <article>

                    <div className={"text-lg lg:text-xl"}>
                        {`${publishedAt.getDate()}. ${publishedAt.toLocaleString('de-de', {month: 'long'})} ${publishedAt.getFullYear()}`}
                    </div>
                    <div className={"news-header flex flex-row flex-wrap items-baseline"}><h4><Link
                        to={`/aktuelles/${attr.slug}`}>{attr.title}</Link></h4>
                        <div onClick={() => {
                            navigator.clipboard.writeText(news_link);
                            setCopiedLink(news_link)
                        }}
                             className={classNames(focusNewsId === key ? "opacity-100" : "opacity-0", "ml-4 cursor-pointer transition-all duration-300 group overflow-visible relative text-xs")}>
                        <span
                            className={"transition-all duration-300 absolute z-50 -top-6 group-hover:-top-8 opacity-0 group-hover:opacity-100 w-52 bg-accent text-white rounded-full text-center py-1.5 px-2.5"}>
                            {copiedLink !== news_link ? "Link zum Artikel kopieren" : "Link in Zwischenablage kopiert!"}
                        </span>
                            <span className={"cursor-pointer hover:text-accent"}>&rsaquo;&nbsp;Artikel teilen</span>
                        </div>
                    </div>
                    <div className={"main-content text-lg lg:text-xl w-full"}>
                        <BlocksRenderer content={content_teaser}/>
                    </div>
                    {show_more && <Link
                        className={"uppercase text-accent border border-accent rounded-md ml-4 py-2.5 mb-24 px-8 hover:text-white hover:bg-accent transition-all duration-300 flex items-center w-fit"}
                        to={`/aktuelles/${attr.slug}`}>&rsaquo;&nbsp;&rsaquo;&nbsp;Weiterlesen</Link>}
                </article>
            </li>

        } else {
            return null
        }

    })

    return (
        <div className={"news-list"}>
            <ul>
                {news_list}
            </ul>
            {(news.length > 0 && news.length > numNews) &&
                <button
                    className={"border-2 border-accent rounded-md uppercase mt-12 py-2.5 px-8 text-accent hover:text-white hover:bg-accent transition-all duration-300 inline-block"}
                    onClick={() => {
                        setNumNews(numNews + 3)
                    }}>Zeige ältere Nachrichten</button>
            }
        </div>
    )

}