import {Link} from "react-router-dom";
import logo from "../logo.png"
import classNames from "classnames";

export default function Logo(props) {

    return (
        <div className={classNames("logo transition-all duration-300 ml-1.5 md:ml-2.5 uppercase font-bold", props.footer ? "text-lg" : "max-h-5 text-lg md:max-h-20 md:text-3xl text-accent ", props.isScrolled ? "opacity-100" : "opacity-0")}>
            <Link to={"/"} className={"h-full w-fit flex flex-row justify-start items-center "}>
                <img width={2652} height={2748} src={logo} alt={"Tischlerei Kroll Logo"} className={classNames("w-16 md:w-32 rounded-full")}/>
                <h1 className={classNames("header-logo w-full max-w-[230px] !leading-[0.92em] block text-accent  p-2.5 rounded-md ")}>Tischlerei Kroll</h1>
            </Link>

        </div>
    )
}