export default function Gallery(props) {
    const data = props.gallery
    const api_root_url = process.env.REACT_APP_API_ROOT_URL

    if(data) {


    const gallery = data.map((item) => {
        const attr = item.attributes
        const formats = attr.formats
        const original_img = attr.url
        const alt_text = attr.alternativeText
        const caption = attr.caption

        const images = Object.values(formats)
        images.sort((n1, n2) => (n1.width < n2.width) ? 1 : (n1.width > n2.width) ? -1 : 0)

        const img_sources = Object.entries(images).map(([key, value]) => {
            return <source className="w-full" key={key} media={`(max-width: ${value.width}px)`}
                           srcSet={`${api_root_url}${value.url}`}/>
        })
        return (
            <li className={"banner relative w-full mb-24 overflow-hidden"}>
                <picture className={"w-full max-h-full"}>
                    {img_sources}
                    <img className="w-full max-h-screen object-cover" loading="lazy" src={`${api_root_url}${original_img}`}
                         alt={alt_text !== undefined ? alt_text : ""}/>
                    {caption && <figcaption className={"hidden"}>{caption}</figcaption>}
                </picture>
            </li>
        )
    })

    return (
        <ul className={"gallery w-full overflow-hidden"}>
            {gallery}
        </ul>
    )} else {
        return null
    }
}