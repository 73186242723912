import {useEffect, useState} from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import classNames from "classnames";

export default function FooterNavigation(props) {
    const [error, setError] = useState(null);
    const [navigation, setNavigation] = useState([]);

    const api_root_url = process.env.REACT_APP_API_ROOT_URL
    useEffect(() => {
        axios
            .get(`${api_root_url}/api/navigation/render/footer-navigation?type=TREE`)
            .then(({data}) => {
                setNavigation(data)
            })
            .catch((error) => setError(error));
    }, [api_root_url]);

    if (error) {
        // Print errors if any
        return <div>An error occurred: {error.message}</div>;
    }

    return (
        <nav className={"footer-nav"}>
            <ul className={"flex flex-col justify-start mt-12 lg:mt-[288px]"}>
                {navigation.map(nav_item => (
                    <li className={"lg:text-right mb-1.5"} key={nav_item.slug}>
                        <Link className={classNames("border-b hover:border-white transition-all duration-150", props.active_trail === nav_item.path ? "border-white" : "border-transparent")} to={nav_item.path} target={nav_item.external ? "_blank" : "_self"} rel="noopener noreferrer">{nav_item.title}</Link>
                    </li>
                ))}
            </ul>
        </nav>
    )

}