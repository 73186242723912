export default function Banner(props) {
    const header = props.data
    let landscape_formats = undefined
    let portrait_formats = undefined
    let original = undefined
    let alternative_text = undefined
    let caption = undefined
    let banner = undefined

    const api_root_url = process.env.REACT_APP_API_ROOT_URL


    if(header && header.landscape.data !== null) {
        banner = header.landscape.data.attributes
        caption = banner.caption
        landscape_formats = banner.formats
        original = banner.url
        alternative_text = banner.alternativeText
    } else {
        landscape_formats = {}
    }

    if(header && header.portrait.data !== null) {
        const portrait = header.portrait.data.attributes
        portrait_formats = portrait.formats
    }else {
        portrait_formats ={}
    }

    if(banner && header && landscape_formats && portrait_formats) {

        const landscape_images = Object.values(landscape_formats)
        const portrait_images = Object.values(portrait_formats)

        landscape_images.sort((n1, n2) => (n1.width < n2.width) ? 1 : (n1.width > n2.width) ? -1 : 0)
        portrait_images.sort((n1, n2) => (n1.width < n2.width) ? 1 : (n1.width > n2.width) ? -1 : 0)

        
        const img_sources_landscape = Object.entries(landscape_images).map(([key, value]) => {
            return <source width={value.width} height={value.height} className="w-full" key={key} media={`(orientation: landscape) and (max-width: ${value.width}px)`} srcSet={`${api_root_url}${value.url}`} />
        })
        const img_sources_portrait = Object.entries(portrait_images).map(([key, value]) => {
            return <source width={value.width} height={value.height} className="w-full" key={key} media={`(orientation: portrait) and (max-width: ${value.width}px)`} srcSet={`${api_root_url}${value.url}`} />
        })
        return (
            <div className={"banner relative w-full mb-24 overflow-hidden"}>
                {!props.isFront && <div className={"curtain absolute w-full h-full top-0"} />}
                <picture className={"w-full"}>
                    {img_sources_landscape}
                    {img_sources_portrait}
                    <img className="w-full" src={`${api_root_url}${original}`}
                         width={banner.width} height={banner.height}
                         alt={alternative_text !== undefined ? alternative_text : "Banner"}/>
                    {caption && <figcaption className={"hidden"}>{caption}</figcaption>}
                </picture>
            </div>
        )
    }
    return ( <div className={"banner w-full h-48 overflow-hidden"} />

    )


}