import {Link, useLoaderData} from "react-router-dom";
import Banner from "../components/Banner";
import MainContent from "../components/MainContent";
import Seo from "../components/Seo";
import React from "react";


export default function News(props) {
    let data = useLoaderData()

    if(data !== null || true) {
        data = data.data[0]
    }

    if (data === undefined || data === null) {
        return <div>No data</div>;
    } else {
        let title, slug, published, attr, header = undefined
        let content = []


        attr = data.attributes
        slug = attr["slug"]
        title = attr["title"]
        content = attr["content"] ? attr["content"] : []
        // gallery = Object.keys(attr["gallery"]).length > 0 ?
        published = attr["publishedAt"] ? new Date(attr.publishedAt) : undefined
        header = attr["header"] ? attr["header"] : undefined

        return (
            <main className={"main"}>
                <Seo data={attr["seo"]} published={published} full_slug={`aktuelles/${slug}`} content_type={"article"} site_title={title}/>
                <article>
                    <Banner data={header} isFront={false}/>

                    <div className={"flex flex-col items-center w-full"}>
                        <div
                            className={"inner w-full lg:w-11/12 2xl:w-10/12 max-w-4xl flex flex-row flex-wrap justify-center"}>
                            <div className={"breadcrumbs text-lg lg:text-xl mb-5 flex flex-row justify-start w-full px-4"}>
                                <Link to={"/aktuelles"}
                                      className={"border-b border-transparent transition-all duration-300 hover:text-accent hover:border-accent"}>Aktuelles</Link>
                                <span className={"mx-1.5"}>&nbsp;&rsaquo;&nbsp;</span>
                                <Link to={`/aktuelles/${slug}`}
                                      className={"border-b border-transparent transition-all duration-300 hover:text-accent hover:border-accent"}>{title}</Link>
                            </div>
                            {published &&
                                <div className={"w-full text-lg lg:text-xl px-4 my-8"}>
                                    {`${published.getDate()}. ${published.toLocaleString('de-de', {month: 'long'})} ${published.getFullYear()}`}
                                </div>
                            }
                            <MainContent published={published} title={title} content={content}/>
                            <Link to={"/leistungen"}
                                  className={"uppercase border border-accent text-accent rounded-md py-2.5 mb-24 px-8 hover:text-white hover:bg-accent transition-all duration-300"}>
                                Das können wir für Sie tun</Link>
                        </div>
                    </div>
                </article>
            </main>
        )
    }
}