import MainNavigation from "./nav/MainNavigation";
import Logo from "./Logo";
import classNames from "classnames";
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import {useEffect, useState} from "react";

export default function ContentHeader(props) {
    const is_front = props.active_trail === "/" || props.active_trail === "/willkommen"
    const [scrolled, setScrolled] = useState(!is_front)

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


    useEffect(() => {
        setScrolled(!is_front)
        function handleResize()  {
            setWindowDimensions(getWindowDimensions());
        }

        if(is_front) {
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [is_front]);

    useScrollPosition(

        ({ currPos }) => {

            if(is_front) {
                setScrolled(currPos.y * -1 >= windowDimensions.height)
            } else {
                setScrolled(true)
            }
        }, [is_front],
    )
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }


    return (
        <header className={classNames("transition-all duration-300 w-full flex justify-between items-center h-fit mt-5 lg:ml-6 lg:mt-6 absolute z-10 bg-transparent")}>
            <Logo isScrolled={scrolled} />
            <MainNavigation active_trail={props.active_trail} isScrolled={scrolled}/>
        </header>
    )
}